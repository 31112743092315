import { useState } from "react";
import { storage, database } from '../Firebase';
import { ref, push, serverTimestamp, set } from "firebase/database";
import { ref as storageRef1, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
const { v4: uuidv4 } = require('uuid'); // If using uuid library


function UpTest() {
  const [imgUrl, setImgUrl] = useState(null);
  const [iimgUrl, setIimgUrl] = useState(null);
  const [yvlink, setYvlink] = useState(null);

  const [progresspercent, setProgresspercent] = useState(0);
  const [title, setTitle] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [newItem, setNewItem] = useState({
    type: 'image',
    duration: '5',
    visible: 'true'
  });

  const handleChange = async (e, field) => {
    
    // console.log(e); // Check the event object
    // console.log(e.target.files); // Check the files array
    const file = e.target.files[0];
    if (file) {
      const resizedImage = await handleImageResize(file);
      setSelectedFile(resizedImage);
      setIimgUrl(URL.createObjectURL(file));
    } else {
      setSelectedFile(null);
      setIimgUrl(null);
    }

    setNewItem({
      ...newItem,
      [field]: e.target.value
    });
  };

  const navigate = useNavigate();
  const handleImageResize = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = 1920;
          canvas.height = 1080;
          ctx.drawImage(img, 0, 0, 1920, 1080);
          canvas.toBlob((blob) => {
            resolve(blob);
          }, 'image/jpeg', 0.9);
        };
      };
    });
  };



  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const file = selectedFile;

    if (!file && newItem.type === 'image') {
      toast.error("Please select a file.");
      return;
    }

    if (!file.type.startsWith('image/') || file.size > 1024000) {
        toast.warning("Please select a valid image file less than 1 MB.");
        return;
      }
    if (!title) {
      toast.error("Please Enter a Title.");
      return;
    }

    // Resize the image
    let resizedImage = null;
    if (file) {
      resizedImage = await handleImageResize(file);
    }
    const randomFileName = uuidv4(); // or nanoid()

    const storageRef = storageRef1(storage, `files/${randomFileName}`);
    const uploadTask = uploadBytesResumable(storageRef, resizedImage);

    uploadTask.on("state_changed",
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgresspercent(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImgUrl(downloadURL);
  // Retrieve the value of the 'id' cookie

          // Create a reference to the 'images' node in the database
          const imagesRef = ref(database, 'displays/vnr/ece'+'/img');

          // Push a new child node under 'images' and get a reference to it
          const newImageRef = push(imagesRef);

          // Set the data for the new child node
          set(newImageRef, {
            title: title,
            url:  newItem.type === "image"?downloadURL:yvlink,
            uploadDate: serverTimestamp(),
            type: newItem.type,
            duration: newItem.duration,
            visible: newItem.visible === 'true'
          });

           // Toast message upon successful upload
        toast.success("Uploaded successfully");

        // Navigate to /dashboard
        navigate("/dashboard");


        });
      }


    );
  };

  return (
    <div>
      <Navbar />
      <div className="max-w-lg mx-auto p-4 mt-20">
        <h1 className="card" style={{ fontWeight: 'bold', padding: 10, margin: 15, fontSize: 20 }}>Add a Container</h1>

        <ToastContainer />

        <form onSubmit={handleSubmit} className='form'>
          <label htmlFor="type" className="block mb-2">
            Type:
            <select
              id="type"
              name="type"
              value={newItem.type}
              onChange={(e) => setNewItem({ ...newItem, type: e.target.value })}
              className="border p-2 w-full"
            >
              <option value="image">Image</option>
              <option value="youtube">Youtube Link</option>
              <option value="video">Video Link</option>
            </select>
          </label>

          {newItem.type === 'image' && (
            <div>
              <input
                type='file'
                onChange={(e) => handleChange(e, 'file')}
                className="hidden"
                id="file"
                name="file"
              />
              <label htmlFor="file" className="border border-gray-300 px-4 py-2 mb-4 cursor-pointer flex items-center justify-center">
                {selectedFile ? (
                  <img src={iimgUrl} alt='selected file' className="w-8 h-8 mr-2" />
                ) : (
                  <svg className="w-8 h-8 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 12a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                    <path fillRule="evenodd" d="M15 10a5 5 0 11-10 0 5 5 0 0110 0zM2 10a8 8 0 1116 0 8 8 0 01-16 0z" clipRule="evenodd" />
                  </svg>
                )}
                {selectedFile ? 'Change File' : 'Select File'}
              </label>
            </div>
          )}

          {newItem.type != 'image' && (
            <input
              type='text'
              placeholder='Enter URL'
              value={yvlink} // Use imgUrl state for URL input
              onChange={(e) => setYvlink(e.target.value)}
              className="border border-gray-300 px-4 py-2 mb-4 w-full"
            />
          )}

          <input
            type='text'
            placeholder='Enter image title'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="border border-gray-300 px-4 py-2 mb-4 w-full"
          />

          <label htmlFor='image-duration' className='block mb-2'>
            Duration:
            <select
              id='image-duration'
              name='duration'
              value={newItem.duration}
              onChange={(e) => setNewItem({ ...newItem, duration: e.target.value })}
              className='border p-2 w-full'
            >
              <option value='5'>5 secs</option>
              <option value='10'>10 secs</option>
              <option value='15'>15 secs</option>
              <option value='30'>30 secs</option>
              <option value='45'>45 secs</option>
              <option value='60'>60 secs</option>
              <option value='90'>90 secs</option>
              <option value='120'>120 secs</option>
            </select>
          </label>

          <label htmlFor='image-visible' className='block mb-2'>
            Visible:
            <select
              id='image-visible'
              name='visible'
              value={newItem.visible}
              onChange={(e) => setNewItem({ ...newItem, visible: e.target.value })}
              className='border p-2 w-full'
            >
              <option value='true'>True</option>
              <option value='false'>False</option>
            </select>
          </label>

          <button type='submit' className="bg-blue-500 text-white px-4 py-2 rounded">{newItem.type != "image"?"Submit":"Upload"}</button>
        </form>

        {!imgUrl && newItem.type === "image" && (
          <div className='outerbar'>
            <div className='innerbar' style={{ width: `${progresspercent}%` }}>{progresspercent}%</div>
          </div>
        )}
        {imgUrl && newItem.type === "image" && <img src={imgUrl} alt='uploaded file' height={200} className="mt-4" />}
      </div>
    </div>
  );
}

export default UpTest;
