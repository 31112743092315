// firebaseConfig.js
const firebaseConfig = {
    apiKey: "AIzaSyBmJGFP4rDzwGBpbJvNxU8K5mIyisdWogI",
    authDomain: "edisplay-saola.firebaseapp.com",
    databaseURL: "https://edisplay-saola-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "edisplay-saola",
    storageBucket: "edisplay-saola.appspot.com",
    messagingSenderId: "889597694032",
    appId: "1:889597694032:web:2f9b1baee6f7ca09b3c155"
  };
  export default firebaseConfig;
  