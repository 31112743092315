import React, { Component } from 'react';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import ReactPlayer from 'react-player/youtube';
import firebaseConfig from '../firebaseConfig';
import VideoPlayer from './videoplay';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const firestore = getFirestore(app);

class Display1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      displayItems: [],
      scrollData: [],
      intervalId: null,
    };
  }

  componentDidMount() {
    this.setupFirebaseListeners();
  }

  setupFirebaseListeners = () => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const id = params.get('id');
    if (id) {
      this.setupRealtimeDatabaseListener(id);
      this.setupFirestoreListener(id);
    }
  };

  setupRealtimeDatabaseListener = (id) => {
    const displayRef = ref(database, `displays/vnr/${id}/img`);
    this.realtimeDBUnsubscribe = onValue(displayRef, (snapshot) => {
      if (snapshot.exists()) {
        const displayData = snapshot.val();
        const displayItems = Object.values(displayData).filter(item => item.visible === true);
        this.setState({ displayItems }, this.startDisplayLoop);
      }
    });
  };

  setupFirestoreListener = (id) => {
    const eceDocRef = doc(firestore, 'vnr', id);
    this.firestoreUnsubscribe = onSnapshot(eceDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const eceDocData = docSnapshot.data();
        const scrollData = eceDocData?.scroll || [];
        this.setState({ scrollData });
      }
    });
  };

  componentWillUnmount() {
    // Clear the interval
    if (this.state.intervalId) {
      clearTimeout(this.state.intervalId);
    }
    // Unsubscribe from Firestore and Realtime Database listeners
    if (typeof this.realtimeDBUnsubscribe === 'function') {
      this.realtimeDBUnsubscribe();
    }
    if (typeof this.firestoreUnsubscribe === 'function') {
      this.firestoreUnsubscribe();
    }
  }

  startDisplayLoop = () => {
    const loopFunction = () => {
      const { currentIndex, displayItems } = this.state;
      if (displayItems.length === 0) {
        // If there are no display items, clear any existing timeouts and do not proceed further
        if (this.state.intervalId) {
          clearTimeout(this.state.intervalId);
          this.setState({ intervalId: null });
        }
        return; // Exit the function if there are no display items
      }
  
      const currentItem = displayItems[currentIndex];
      if (!currentItem) {
        // If currentItem is undefined, log an error or handle it as appropriate
        console.error('Current item is undefined. This should not happen.');
        return; // Exit the function to avoid accessing properties of undefined
      }
  
      const duration = currentItem.duration ? currentItem.duration * 1000 : 15000; // Default duration
  
      // Schedule the next display
      this.setState(prevState => ({
        currentIndex: prevState.currentIndex === displayItems.length - 1 ? 0 : prevState.currentIndex + 1,
      }), () => {
        clearTimeout(this.state.intervalId); // Clear existing timeout before setting a new one
        const intervalId = setTimeout(loopFunction, duration); // Set new timeout with updated duration
        this.setState({ intervalId });
      });
    };
  
    loopFunction(); // Start the loop
  };
  

  renderDisplayItem = (item) => {
    switch(item.type) {
      case 'image':
        return <img className='pt-1' src={item.url} alt="Display" style={{ width: '100%', height: '100%', objectFit: 'fill' }} />;
      case 'video':
        return <VideoPlayer item={item}/>

      case 'youtube':
        return <VideoPlayer item={item}/>
        
      default:
        return null;
    }
  };

  render() {
    const { displayItems, currentIndex, scrollData } = this.state;
    const currentItem = displayItems[currentIndex] || {};

    return (
      <div style={{ overflow: 'hidden', height: '100vh' }}>
        {displayItems.length > 0 && (
          <div style={{height:"90vh"}}>
            {this.renderDisplayItem(currentItem)}
          </div>
        )}

        <div>
          <marquee scrollamount="26" style={{ fontSize: 40 }}>
            {scrollData.map((item, index) => (
              <b key={index}>&#8226; {item} </b>
            ))}
          </marquee>
        </div>
      </div>
    );
  }
}

export default Display1;
