import logo from './logo.svg';

import Navbar from "./components/Navbar";
import Header from "./components/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";
import { Route, Routes } from 'react-router-dom';
import Display1 from './components/Display1';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import Dashboard from './pages/Dashboard';
import { AuthContextProvider } from './AuthContext';
import ProtectedRoute from './ProtectedRoute';
import UpTest from './pages/testupload';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import GoogleSlidesPresentation from './pages/testfd';

function App() {
  return (
    <div className="App">
    
      <ToastContainer/>
     {/* <Navbar/> */}

<AuthContextProvider>


      <Routes>
     <Route path="/" element={<Main />} />
     <Route path="/addContainer" element={
     <ProtectedRoute>
     <UpTest/>
     </ProtectedRoute>} />
    

     {/* <Route path="/contactus" element={<Contactus/>}/> */}
     {/* <Route path="/register/" element={<Registration/>}/>
     <Route path="/about" element={<About/>}/>
     
     <Route path="/IoTSprint2k22/:id/:name/:cert" element={<CertificateView/>}/> */}
     <Route path="/display/vnr/" element={<Display1/>}/>
     <Route path="/login" element={<Login/>} />
     <Route path="/register" element={<SignUp/>} />

<Route path="/test" element={<GoogleSlidesPresentation/>}/>
     <Route path="/dashboard" element={
     <ProtectedRoute>
      <Dashboard/>
     </ProtectedRoute>}/>

    </Routes>
    </AuthContextProvider>
    </div>
  );
}


export default App;
