// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBmJGFP4rDzwGBpbJvNxU8K5mIyisdWogI",
    authDomain: "edisplay-saola.firebaseapp.com",
    databaseURL: "https://edisplay-saola-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "edisplay-saola",
    storageBucket: "edisplay-saola.appspot.com",
    messagingSenderId: "889597694032",
    appId: "1:889597694032:web:2f9b1baee6f7ca09b3c155"
  };

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db= getFirestore(app);
export const database= getDatabase(app);
export const storage = getStorage(app);