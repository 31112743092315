
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';


import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom'
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that y
import 'firebase/database';
import firebaseConfig from './firebaseConfig'; // Path to your Firebase configuration file

// Initialize Firebase

const root = ReactDOM.createRoot(document.getElementById('root'));
 initializeApp(firebaseConfig);
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <App />
    </BrowserRouter>
  </React.StrictMode>
);
reportWebVitals();
