import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';

const VideoPlayer = ({ item }) => {
  // State to manage whether the video is muted
  const [muted, setMuted] = useState(true);

//   useEffect(() => {
//     // Set a timer to unmute the video after 5 seconds (5000 milliseconds)
//     const timer = setTimeout(() => {
//       setMuted(false);
//     }, 5000); // Adjust the time as needed

//     // Clean up the timer if the component unmounts before the timer fires
//     return () => clearTimeout(timer);
//   }, []); // Empty dependency array ensures this effect runs only once after the initial render

  return (
    <ReactPlayer
      url={item.url}
      playing
      controls={false}
      width="100%"
      height="100%"
      muted={muted}
      config={{
        youtube: {
          playerVars: {
            autoplay: 1,
            controls: 0,
            mute: muted ? 1 : 0, // Use the state variable to control muting
          }
        }
      }}
    />
  );
};

export default VideoPlayer;
