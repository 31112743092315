import About from '../components/About';
import Services from '../components/Services';
import React, { Component } from 'react'
import Footer from './Footer';
import Navbar from './Navbar';
import Header from './Header';

// function Main() {
//   return (
//     <>
//       <main>
     
//       </main>
//       <Footer />
//     </>
//   );
// }
// export default Main;




export default class Main extends Component {
  render() {
    return (
      <div>
         <Navbar />
      <Header />
        <Services />
        {/* <About /> */}
        {/* <Contact /> */}
      </div>
    )
  }
}
