
import Card from "./Card";

function Services() {
  return (
    <div className="container services">
      <h2 className="main-title text-center">SERVICES</h2>
      <div className="card-cover">
        <div className="col-md-12">
          <div className="row">
          <div className="col-md-4 mb-2">
          <Card
  title="Web Development"
  img="card1.png"
  text="Crafting seamless and responsive websites tailored to meet your unique business requirements."
/>
  </div>
            <div className="col-md-4 mb-2">
              <Card
                title="E-Display"
                img="card1.png"
                text="Enhance your brand visibility and engage your audience with our cutting-edge E-Display solutions."
              />
            </div>
            <div className="col-md-4 mb-2">
              <Card
                title="Cloud Printing"
                img="card2.png"
                text="Efficient and reliable cloud printing services tailored to meet your business needs."
              />
            </div>
            <div className="col-md-4 mb-2">
              <Card
                title="App Development"
                img="card3.png"
                text="Turn your ideas into reality with our customized App Development services."
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
