import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
// import { UserAuth } from '../AuthContext'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { auth,db } from '../Firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
const Login = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  let navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const userCredential = await signInWithEmailAndPassword(auth,email, password);

      // Access user details using the user's email
      const uemail = userCredential.user.email;
      
   
const userDocRef = doc(db, 'users', uemail);

// Now, you can use this reference to perform Firestore operations, such as fetching the document
getDoc(userDocRef)
  .then((snapshot) => {
    if (snapshot.exists()) {
      const userData = snapshot.data();
      console.log('User Data:', userData);
      // When setting a cookie
document.cookie = "id="+userData.id+"; secure; HttpOnly";
document.cookie = "org="+userData.org+"; secure; HttpOnly";
document.cookie = "welmsg="+userData.msg+"; secure; HttpOnly";

document.cookie = "subType="+userData.subType+"; secure; HttpOnly";

navigate('/dashboard');

    } else {
      toast.error('You are Not Subscribed to our Services. Please Contact us to Subscribe.');
      console.log('User document does not exist.');
    }
  })
  .catch((error) => {
    console.error('Error getting user document:', error);
  });

      // Redirect or perform any other actions after successful login
     
    } catch (error) {
      setError(error.message);
    }
  };


  return (
    <>
    <ToastContainer/>
    <div className='w-full h-screen'>
      <img 
      className='hidden sm:block absolute w-full h-full object-cover'
      src="https://t4.ftcdn.net/jpg/02/16/47/33/360_F_216473351_FCLq1pZQOBFrgcyPBphKvBd8Z5wjD1dI.jpg"
         alt='/'
      />
      <div className='bg-black/60 fixed top-0 left-0 w-full h-screen'></div>
      <div className='fixed w-full px-4 py-24 z-50'>
          <div className='max-w-[450px] h-[600px] mx-auto bg-black/75 text-white' >
            <div className='max-w-[320px] mx-auto py-16'>
              <h1 className='text-3xl font-bold'> Sign In</h1>
              { error? <p className='p-3 bg-red-400 py-2 mt-4 items-center'> {error}</p>: null}
              <form onSubmit={handleSubmit} className='w-full flex flex-col py-4'>
                <input
                 onChange={(e) => setEmail(e.target.value)}
                className='p-3 my-2 bg-gray-700 rounded' type="email" placeholder='Email' autoComplete='email'/>
                <input
                onChange={(e) => setPassword(e.target.value)}
                className='p-3 my-2 bg-gray-700 rounded' type="password" placeholder='Password' autoComplete='current-password' />
                <button className='bg-red-600 py-3 my-6 rounded font-bold'>
                  Sign In
                </button>
                <div className='flex justify-between items-center text-sm text-gray-600'>
                  <p><input className='mr-2 ' type="checkbox"/> Remember me</p>
                  <p> Need Help?</p>
                </div>
                <p className='py-4'> <span className='text-gray-600'> New to E Display? 
                </span>{ ' ' }
                  
                  <Link to='/register'> Sign Up</Link>
                  </p>
              </form>

            </div>

          </div>
      </div>
    </div>
    </>
  )
}

export default Login
