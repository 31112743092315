import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../components/Navbar';
import {
  collection,
  addDoc,
  getDocs,
  query,
  orderBy,
  updateDoc,
  arrayUnion,
  arrayRemove,
  getFirestore,
  doc,
  getDoc,
} from 'firebase/firestore';

/* Import these styles in your global or component styles */
// import 'react-beautiful-dnd/style.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";

import {
  ref,
  onValue,
  set,
  child,
  update,
  remove,
  push,
} from 'firebase/database';
import firebaseConfig from '../firebaseConfig';

import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';




export default function Dashboard() {
  const [showPreview, setShowPreview] = useState(false);
  const handlePreview = () => {
    setShowPreview(!showPreview);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };
  const [accomDatas, setAccomDatas] = useState([])
  const [accomData, setAccomData] = useState([]);

  const [textContainers, setTextContainers] = useState([]);
  const [textKeys, setTextKeys] = useState([]);
  const [addingText, setAddingText] = useState(false);
  const [newText, setNewText] = useState('');

  const [editingIndex, setEditingIndex] = useState(null);
  const [editData, setEditData] = useState({ });

  const [addingItem, setAddingItem] = useState(false);
  const navigate = useNavigate();

  
  const handleEdit = (index) => {
    setEditingIndex(index);
    setEditData({ ...accomData[index] });
   
    
  };
  
  const handleSaveEdit = () => {
    const newData = [...accomData];
    newData[editingIndex] = { ...editData };
    setAccomData(newData);
    updateDataInDatabase(editData, editingIndex);
    setEditData({});
    setEditingIndex(null);

  };


  const handleAddContainer = () => {
    navigate("/addContainer");
  };

  const handleDelete = (index) => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this container?'
    );
    if (confirmDelete) {
      const newData = [...accomData];
      newData.splice(index, 1);
      setAccomData(newData);
      // toast.info('Deleted Successfully!');
      const itemToDelete = accomDatas[index];
      console.log(itemToDelete);
      const itemRef = ref(database, `displays/vnr/ece/img/${itemToDelete}`); // Adjust the path as necessary
    
      remove(itemRef)
        .then(() => {
          // console.log("");
          toast.success('Item deleted successfully.');

          // Optionally, update the local state to reflect the change
          const updatedAccomData = accomData.filter((_, i) => i !== index);
          setAccomData(updatedAccomData);
        })
        .catch((error) => {
          console.error("Could not delete item: ", error);
        });
    }
  };

  

 
 
  

  const handleDeleteTextContainer = async (index) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this text?');
    if (confirmDelete) {
      try {
        // Assuming 'scroll' is a field in the Firestore document
        const eceDocRef = doc(firestore, 'vnr', 'ece');
        const eceDocSnapshot = await getDoc(eceDocRef);
  
        if (eceDocSnapshot.exists()) {
          const currentTextContainers = eceDocSnapshot.data()?.scroll || [];
          
          // Remove the text container at the specified index
          const updatedTextContainers = [...currentTextContainers];
          updatedTextContainers.splice(index, 1);
  
          // Update the 'scroll' field in the Firestore document
          await updateDoc(eceDocRef, { scroll: updatedTextContainers });
  
          // Update the state with the new textContainers
          setTextContainers(updatedTextContainers);
  
          toast.info('Text Container Deleted Successfully!');
        }
      } catch (error) {
        console.error('Error deleting text container:', error);
      }
    }
  };
  

  const handleAddTextContainer = () => {
    setAddingText(true);
    setAddingItem(false); // Make sure to set addingItem to false
  };

  const handleSaveText = async () => {
    try {
      // Create a new textContainer object
    
      // Get a reference to the Firestore document
      const eceDocRef = doc(firestore, 'vnr', 'ece');
  
      // Fetch the current document data
      const eceDocSnapshot = await getDoc(eceDocRef);
      const eceDocData = eceDocSnapshot.data();
  
      // Extract the current scroll array or create an empty array
      const currentScroll = eceDocData?.scroll || [];
  
      // Update the scroll array with the new textContainer
      const updatedScroll = [...currentScroll, newText];
  
      // Update the Firestore document with the new scroll array
      await updateDoc(eceDocRef, {
        scroll: updatedScroll,
      });
  
      // Update the state with the new scroll array
      setTextContainers(updatedScroll);
  
      // Reset the input field and state
      setAddingText(false);
      setNewText('');
    } catch (error) {
      console.error('Error saving text:', error.message);
      // Handle error appropriately, e.g., show a notification to the user
    }
  };
  


  // const handleChange = (e, containerType) => {
  //   const { name, value } = e.target;

  //   if (containerType === 'text') {
  //     setNewText(value);
  //   } else {
  //     setNewItem((prevItem) => ({ ...prevItem, [name]: value }));
  //   }
  // };

  const handleChange = (e, containerType) => {
    const { name, value } = e.target;

    if (containerType === 'text') {
      setNewText(value);
    } else if (editingIndex !== null) { // Editing an existing item
      setEditData(prevItem => ({ ...prevItem, [name]: value }));
    } 
  };

  const updateDataInDatabase = (updatedData, index) => {
    const containersRef = ref(database, `displays/vnr/ece/img/${accomDatas[index]}`);
    set(containersRef, updatedData).then(
      toast.success("Updated Successfully"),
    );
  };

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const app = initializeApp(firebaseConfig);

  const database = getDatabase(app);
  const firestore = getFirestore(app);

  useEffect(() => {
    const containersRef = ref(database, `displays/vnr/ece/img`);

    onValue(containersRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        if (data) {
          const dataArray = Object.values(data);
          const keys = Object.keys(data);
          setAccomDatas(keys);
          setAccomData(dataArray);
        }
      }
    });
  }, []);

  const handleMoveItem = async (currentIndex, direction) => {
    const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
  
    if (newIndex < 0 || newIndex >= textContainers.length) {
      // Skip if moving out of bounds
      return;
    }
  
    const updatedContainers = [...textContainers];
    const [movedItem] = updatedContainers.splice(currentIndex, 1);
    updatedContainers.splice(newIndex, 0, movedItem);
  
    setTextContainers(updatedContainers);
  
    // Update the order in the Firestore database
    try {
      await updateDoc(doc(firestore, 'vnr/ece'), {
        scroll: updatedContainers
      });
    } catch (error) {
      console.error('Error updating the order');
      // Handle error
    }
  };
  useEffect(() => {
    const eceDocRef = doc(firestore, 'vnr', 'ece');
  
    const fetchTextContainers = async () => {
      const eceDocSnapshot = await getDoc(eceDocRef);
  
      if (eceDocSnapshot.exists()) {
        const eceDocData = eceDocSnapshot.data();
        const textContainersList = eceDocData?.scroll || [];
        setTextContainers(textContainersList);
      }
    };
  
    fetchTextContainers();
  }, [firestore]);
  
  
  return (
    <div>
      <Navbar />
      <ToastContainer />

      <div className='content-container mt-20 p-5'>
        <h1 className='text-4xl font-bold'>Hello Department of ECE 👋</h1>
        <br />
        <div className='mb-8 p-4 bg-blue-200 text-gray-800 rounded-md shadow-md'>
          <h2 className='text-2xl font-bold'>
            Number of Images Streaming: {accomData.length}
          </h2>
          <h2 className='text-2xl font-bold'>
            Number of Text Containers: {textContainers.length}
          </h2>
          <br />
          <div className='flex flex-col md:flex-row items-center gap-4 md:gap-8'>
            <p
              className='bg-teal-500 hover:bg-teal-700 text-white font-semibold py-3 px-6 rounded-xl cursor-pointer'
              onClick={
               handleAddContainer
              }
            >
              Add Container
            </p>
            <p
              className='bg-teal-500 hover:bg-teal-700 text-white font-semibold py-3 px-6 rounded-xl cursor-pointer'
              onClick={handleAddTextContainer}
            >
              Add Text
            </p>
            <p
              className='bg-teal-500 hover.bg-teal-700 text-white font-semibold py-3 px-6 rounded-xl cursor-pointer'
              onClick={handlePreview}
            >
              Preview
            </p>
          </div>
          

         
{/* 
// Example button triggering reordering
<button onClick={() => handleReorder(startIndex, endIndex)}>
  Reorder
</button> */}

        </div>
        <br />

        <div className='bg-blue-200 text-gray-800 p-8 rounded-xl shadow-md mb-4 content-container'>
        <h2 className='text-2xl font-bold mb-4'>Text Containers</h2>
        {textContainers.length == 0 && <div>
          <h1 className='text-4xl font-bold'> No Text Containers</h1>

         
          </div>}
        {textContainers.map((textContainer, index) => (
  <div
    key={index}
    className='flex flex-col md:flex-row items-center bg-blue-100 text-gray-800 p-4 rounded-md shadow-md mb-4'
  >
    <div className='flex-1'>
      <p className='text-xl font-bold mb-2'>Index: {index + 1}</p>
      <p className='text-lg font-semibold'>{textContainer}</p>
    </div>
    <div className='flex gap-2'>
      <button
        className='bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded'
        onClick={() => handleMoveItem(index, 'up')}
        disabled={index === 0} // Disable the button if it's the first item
      >
        <FontAwesomeIcon icon={faArrowUp} />
      </button>
      <button
        className='bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded'
        onClick={() => handleMoveItem(index, 'down')}
        disabled={index === textContainers.length - 1} // Disable the button if it's the last item
      >
        <FontAwesomeIcon icon={faArrowDown} />
      </button>
      <button
        className='bg-red-500 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded'
        onClick={() => handleDeleteTextContainer(index)}
      >
        X
      </button>
    </div>
  </div>
))}
          <div className='flex gap-4 items-center mt-2'>
            <button
              className='bg-cyan-500 hover:bg-cyan-700 text-white font-semibold py-2 px-4 rounded'
              onClick={handlePreview}
            >
              Preview
            </button>
          </div>
         
        </div>

        {showPreview && (
           <div className='preview-modal fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50'>
           <div className='preview-content bg-white p-8 rounded-md relative max-w-screen-lg w-full overflow-hidden'>
              <button
                className='absolute top-0 right-0 p-4 text-xl cursor-pointer'
                onClick={handleClosePreview}
              >
                &times;
              </button>
              <h2 className='text-2xl font-bold mb-4'>Preview</h2>

              <div className='marquee'>
                {textContainers.map((item, index) => (
                  <p key={index} className='text-lg mb-2'>
                    <b>&#8226; {item}</b>
                  </p>
                ))}
              </div>
            </div>
          </div>
        )}
     

        {addingText && (
         <div className='add-text-modal fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50'>
         <div className='add-text-content bg-white p-8 rounded-md'>
              <h2 className='text-2xl font-bold mb-4'>Add Text</h2>
              <label htmlFor='text-input' className='block mb-2'>
                Text:
                <input
                  type='text'
                  id='text-input'
                  name='text-input'
                  value={newText.text}
                  onChange={(e) => handleChange(e, 'text')}
                  className='border p-2 w-full'
                />
              </label>
              <div className='flex justify-end mt-4'>
                <button
                  className='bg-teal-500 hover:bg-teal-700 text-white font-semibold py-2 px-4 rounded mr-2'
                  onClick={handleSaveText}
                >
                  Save
                </button>
                <button
                  className='bg-gray-500 hover:bg-gray-700 text-white font-semibold py-2 px-4 rounded'
                  onClick={() => setAddingText(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

{accomData.length == 0 && <div>
          <h1 className='text-4xl font-bold'> No  Containers</h1>

         
          </div>}
        {accomData.map((item, index) => (
          <div
            key={index}
            className='item-card bg-blue-100 text-gray-800 p-8 rounded-xl shadow-md mb-4 content-container'
          >
            <div className='flex flex-col lg:flex-row gap-8 lg:items-center'>
              <div className='lg:w-full md:w-1/2'>
                <div className='relative overflow-hidden w-full h-72 mb-4'>
                  <img
                    key={0}
                    src={item.url}
                    alt=''
                    className={`absolute w-full h-full transition-transform transform opacity-100`}
                  />
                </div>
              </div>
              <div className='flex flex-col lg:w-full md:w-1/2 gap-4'>
                <div>
                  <h1 className="text-3xl font-bold">{item.title}</h1>
                  <span className="text-teal-500 font-semibold">Item Type: {item.type}</span>
                </div>
                {editingIndex !== index ? (
                  <p className="text-justify text-2xl font-bold">Duration: {item.duration} Secs</p>
                ) : (
                  <div className="flex gap-4 items-center">
                    <label htmlFor={`duration-${index}`}>Duration:</label>
                    <select
                      id={`duration-${index}`}
                      name="duration"
                      value={editData.duration}
                      onChange={(e) => handleChange(e, 'image')}
                      className="border p-2"
                    >
                      <option value="5">5 secs</option>
                      <option value="10">10 secs</option>
                      <option value="15">15 secs</option>
                      <option value="30">30 secs</option>
                      <option value="45">45 secs</option>
                      <option value="60">60 secs</option>
                      <option value="90">90 secs</option>
                      <option value="120">120 secs</option>
                    </select>
                    <label htmlFor='image-visible' className='block mb-2'>
                Visible:
                <select
                  id='image-visible'
                  name='visible'
                  value={editData.visible}
                  onChange={(e) => handleChange(e, 'visible')}
                  className='border p-2 w-full'
                >
                  <option value='true'>True</option>
                  <option value='false'>False</option>
                  
                </select>
              </label>
                  </div>
                )}
                
                <div className="flex flex-row items-center gap-4">
          <button
            className="bg-teal-500 hover:bg-teal-700 text-white font-semibold py-2 px-4 rounded-xl"
            onClick={() => {
              const updatedData = { ...accomData[index], visible: !accomData[index].visible };

             const newData = [...accomData];
              newData[index] = updatedData;
              setAccomData(newData);

              updateDataInDatabase(updatedData, index);
            }}
          >
            {item.visible ? <p>Hide</p> : <p>Show</p>}
          </button>

          <button
            className="bg-teal-500 hover:bg-teal-700 text-white font-semibold py-2 px-4 rounded-xl"
            onClick={() => handleEdit(index)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded-xl"
            onClick={() => handleDelete(index)}
          >
            Delete
          </button>
        </div>
              </div>
            </div>
          </div>
        ))}
       {editingIndex !== null && (
  <div className='edit-item-modal fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center'>
    <div className='edit-item-content bg-white p-8 rounded-md'>
      <h2 className="text-2xl font-bold mb-4">Edit Item</h2>
      <label htmlFor={`image-url-${editingIndex}`} className="block mb-2">
        Image URL:
        <input
          type="text"
          id={`image-url-${editingIndex}`}
          name="url"
          value={editData.url}
          onChange={(e) => handleChange(e, 'url')}
          className={`border p-2 w-full ${isValidUrl(editData.url) ? 'border-green-500' : 'border-red-500'}`}
        />
        {!isValidUrl(editData.url) && (
          <p className="text-red-500 text-sm mt-1">Please enter a valid URL.</p>
        )}
      </label>
      <label htmlFor={`title-${editingIndex}`} className="block mb-2">
        Title:
        <input
          type="text"
          id={`title-${editingIndex}`}
          name="title"
          value={editData.title}
          onChange={(e) => handleChange(e, 'title')}
          className="border p-2 w-full"
        />
      </label>
      <label htmlFor={`type-${editingIndex}`} className="block mb-2">
        Type:
        <select
          id={`type-${editingIndex}`}
          name="type"
          value={editData.type}
          onChange={(e) => handleChange(e, 'type')}
          className="border p-2 w-full"
        >
          <option value="image">Image</option>
          <option value="youtube">Youtube Link</option>
          <option value="video">Video Link</option>
        </select>
      </label>
      <label htmlFor={`duration-${editingIndex}`} className="block mb-2">
        Duration:
        <select
          id={`duration-${editingIndex}`}
          name="duration"
          value={editData.duration}
          onChange={(e) => handleChange(e, 'duration')}
          className="border p-2 w-full"
        >
          <option value="5">5 secs</option>
          <option value="10">10 secs</option>
          <option value="15">15 secs</option>
          <option value="30">30 secs</option>
          <option value="45">45 secs</option>
          <option value="60">60 secs</option>
          <option value="90">90 secs</option>
          <option value="120">120 secs</option>
        </select>
      </label>
      <label htmlFor='image-visible' className='block mb-2'>
        Visible:
        <select
          id='image-visible'
          name='visible'
          value={editData.visible.toString()}
          onChange={(e) => handleChange(e, 'visible')}
          className='border p-2 w-full'
        >
          <option value='true'>True</option>
          <option value='false'>False</option>
        </select>
      </label>
      <div className="flex justify-end">
        <button
          className="bg-teal-500 hover:bg-teal-700 text-white font-semibold py-2 px-4 rounded mr-2"
          onClick={handleSaveEdit}
        >
          Save
        </button>
        <button
          className="bg-gray-500 hover:bg-gray-700 text-white font-semibold py-2 px-4 rounded"
          onClick={() => {
            setEditingIndex(null);
            setEditData({  });
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
)}

      </div>
    </div>
  );
}
