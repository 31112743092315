
  import React, { useState } from 'react';

  const GoogleSlidesPresentation = () => {
    const [slideIndex, setSlideIndex] = useState(1);
    const [src, setSrc] = useState(`https://docs.google.com/presentation/d/e/2PACX-1vR836uzgMQ0NXoJtRG6alK4x729VdozffMIpZWpbbcCvbLAkSB-FsyQNFtiZ-KEOtD8mAnFToNw7HeL/embed?start=false&loop=false&delayms=3000&slide=id.p${slideIndex}`);
  
    const handleNext = () => {
      const nextSlideIndex = slideIndex + 1;
      setSlideIndex(nextSlideIndex);
      setSrc(`https://docs.google.com/presentation/d/e/2PACX-1vR836uzgMQ0NXoJtRG6alK4x729VdozffMIpZWpbbcCvbLAkSB-FsyQNFtiZ-KEOtD8mAnFToNw7HeL/embed?start=false&loop=false&delayms=3000&slide=id.p${nextSlideIndex}`);
    };
  
    const handlePrev = () => {
      if (slideIndex > 1) {
        const prevSlideIndex = slideIndex - 1;
        setSlideIndex(prevSlideIndex);
        setSrc(`https://docs.google.com/presentation/d/e/2PACX-1vR836uzgMQ0NXoJtRG6alK4x729VdozffMIpZWpbbcCvbLAkSB-FsyQNFtiZ-KEOtD8mAnFToNw7HeL/embed?start=false&loop=false&delayms=3000&slide=id.p${prevSlideIndex}`);
      }
    };
  
    return (
      <div style={{height:"100vh"}}>
        <div>
          <h1>Smart ClassRoom</h1>
        </div>
        <iframe
          src={src}
          width="100%"
          height="95%"
          frameBorder="0"
          allowFullScreen={true}
          title="Presentation"
        />
        {/* <button onClick={handlePrev} disabled={slideIndex === 1}>Previous</button>
        <button onClick={handleNext}>Next</button> */}
      </div>
    );
  };
  
  

export default GoogleSlidesPresentation;
