import { useNavigate } from 'react-router-dom';
function Header() {
  const navigate = useNavigate();
  const handleLoginClick = () => {
    // Redirect to the login page
    navigate('/login');
  };
  return (
    <header>
      <video src="./video.mp4" loop autoPlay muted></video>
      <h1>E Display</h1>
      <div className="row">
        <button className="btn" style={{ cursor: "pointer" }}>
          Sign Up
        </button>

        <button onClick={handleLoginClick} className="btn" style={{ cursor: "pointer" }}>
          Log in
        </button>
      </div>

      <div className="headerbg"></div>
    </header>
  );
}
export default Header;
